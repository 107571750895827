import React, { useEffect, useState } from "react"
const fala = require("./lottie/lottie/II/animacja_fala.json")
import Lottie from "react-lottie-player"
import { Wrapper, Headline, Box, Distance, KM, BoxImage, P } from "./styles"

type Props = {}

const Cosmos: React.FC<Props> = ({}): JSX.Element => {
  const [time, setTime] = useState(
    (new Date().getTime() - new Date(1961, 3, 12).getTime()) * 3000
  )

  useEffect(() => {
    const time = setInterval(() => {
      setTime((new Date().getTime() - new Date(1961, 3, 12).getTime()) * 3000)
    }, 1000)

    return () => {
      clearInterval(time)
    }
  }, [])

  const size = 182
  return (
    <Wrapper>
      <Headline>GDZIE JEST GŁOS LEMA</Headline>
      <Box>
        <Lottie
          loop
          animationData={fala}
          play
          style={{ width: size, height: size }}
        />
        <Distance>
          <KM>{time}</KM> km od Ziemi
        </Distance>
      </Box>
      <BoxImage>
        <img
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
          src="/infografika_desktop_pl.png"
          alt="Cosmos"
        />
      </BoxImage>
      <P>
        Za sto lat głos Lema dotrze do układu, który nazwany został na cześć
        twórczości fantasty.
      </P>
    </Wrapper>
  )
}

export default Cosmos
